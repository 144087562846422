import React, {useEffect, useState} from 'react'
import * as antd from 'antd'
import { useAuth } from "react-oidc-context";
import { ProjectUserLink, User, UserService } from '../services';

const { Typography, Card, Button } = antd;
const { Paragraph, Title } = Typography;
const { Meta } = Card;

type IUserState = {
  user: User;
  fetchUsers: ()=> void
}

const UsersContext = React.createContext<IUserState>({
    user: {email: '', projects: []}, fetchUsers: () => {}
  })

  export default function Users() {
    const auth = useAuth();

    const [user, setUsers] = useState<User>({email: "", projects: [], is_admin: false})
    const fetchUsers = async () => {
      const user = await UserService.apiGetUser()
      setUsers(user)
    }
    useEffect(() => {
      fetchUsers()
    }, [])
    return (
      <UsersContext.Provider value={{user, fetchUsers}}>
        <Card loading={user.email === ''} style={{width: 600}}>
          <Meta title="Your user information" description="You will find the basic details about the current authenticated user here"/>
          <Title level={5}>Email address:</Title><Paragraph copyable={user.email !== ''}>{user.email}</Paragraph>
          <Button onClick={() => void auth.removeUser()}>Sign out</Button>
        </Card>
      </UsersContext.Provider>
    )
  }
