/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Project } from '../models/Project';
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProjectService {
    /**
     * Get Projects
     * Get all projects
     * @returns Project Successful Response
     * @throws ApiError
     */
    public static apiGetProjects(): CancelablePromise<Array<Project>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects',
        });
    }
    /**
     * Post Projects Name
     * Adds a new project
     * @param requestBody
     * @returns Project Successful Response
     * @throws ApiError
     */
    public static apiPostProjectsName(
        requestBody: Project,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/projects',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Projects For User
     * Get all projects belonging to a single user
     * @returns Project Successful Response
     * @throws ApiError
     */
    public static apiGetProjectsForUser(): CancelablePromise<Array<Project>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects/user',
        });
    }
    /**
     * Get Projects Name
     * Get a project by name
     * @param name
     * @returns Project Successful Response
     * @throws ApiError
     */
    public static apiGetProjectsName(
        name: string,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects/{name}',
            path: {
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Projects Signin Uri
     * Get a project signin uri for signed in user
     * @param name
     * @returns string Successful Response
     * @throws ApiError
     */
    public static apiGetProjectsSigninUri(
        name: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects_uri/{name}',
            path: {
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add User To Project
     * Add a user to a project
     * @param name
     * @param user
     * @returns User Successful Response
     * @throws ApiError
     */
    public static apiAddUserToProject(
        name: string,
        user: string,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/user/{name}',
            path: {
                'name': name,
            },
            query: {
                'user': user,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
