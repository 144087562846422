import React, {useEffect, useState,} from 'react'
import * as icons from '@ant-design/icons';
import * as antd from 'antd'
import { useAuth } from "react-oidc-context";
import { MenuClickEventHandler, MenuItemType } from 'rc-menu/lib/interface'
import { UserService } from '../services';

const { Layout, Menu } = antd;
const { Sider } = Layout;
const {  LaptopOutlined, UserOutlined, TeamOutlined  } = icons;

type IUserState = {
  items: MenuItemType[],
  fetchUsers: ()=> void
}

const SidebarContext = React.createContext<IUserState>({
    items: [], fetchUsers: () => {}
  })


  export default function Users(props: { clickhandler: MenuClickEventHandler | undefined; }) {
    let init = {key: "", label: ""}
    const auth = useAuth();
    const [items, setItems] = useState([init])
    const fetchUsers = async () => {
        const token = auth.user?.access_token;
        const user = await UserService.apiGetUser()
      const items = [UserOutlined, LaptopOutlined, TeamOutlined, LaptopOutlined].map((icon, index) => {
        if (index === 0) {
          return {
            key: 'User',
            icon: React.createElement(icon),
            label: "Your profile"
          }
        }
        if (index === 1) {
        return {
          key: 'Projects',
          icon: React.createElement(icon),
          label: "Your projects"
        }
        }
        if (index === 2 && user.is_admin) {
          return {
            key: 'Users',
            icon: React.createElement(icon),
            label: "Admin: All users"
          }
        }
        if (index === 3) {
            return {
                key: 'DB_Projects',
                icon: React.createElement(icon),
                label: "Database projects"
            }
        }
        return {
         key: "",
         label: ""
        }
      });
      setItems(items)
    }
    useEffect(() => {
      fetchUsers()
    }, [])
    return (
      <SidebarContext.Provider value={{items, fetchUsers}}>
        <Sider
            width={200}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={['User']}
              style={{
                height: '100%',
              }}
              items={items}
              onClick={props.clickhandler}
            />
          </Sider>
      </SidebarContext.Provider>
    )
  }
