import React, {useEffect, useState} from 'react'
import * as antd from 'antd'
import { Button, Modal, type FormProps, Form, Input, List } from 'antd'
import { useAuth } from "react-oidc-context";
import { OpenAPI, Project, ProjectService } from '../services';
const { Table, Tag } = antd;

interface IModelForm {
  properties: any
}

type IProjectState = {
  projects: Project[],
  fetchProjects: () => void
}

const ProjectsContext = React.createContext<IProjectState>({
    projects: [], fetchProjects: () => {}
  })

  export default function Projects() {


    const [form] = Form.useForm();

    const onFinish: FormProps["onFinish"] = (values) => {
        // const token = auth.user?.access_token;
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        //     body: JSON.stringify(values)
        // };
        // fetch('/api/create_project', requestOptions)
        //     .then(response => response.json())
        // console.log('Success:', values);

        const project = {
            email: values["User's email"],
        }

        ProjectService.apiAddUserToProject(modalContent, project.email)

    };

    const onFinishFailed: FormProps["onFinishFailed"] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleCreate = () => {
    form
        .validateFields()
        .then((values) => {
        form.resetFields();
        onFinish(values);
        setIsModalOpen(false)

        })
        .catch((info) => {
        console.log("Validate Failed:", info);
        });
    };
    const [projects, setProjects] = useState<Project[]>([])
    const [loaded, setLoaded] = useState(false)
    const [modalContent, setModalContent] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [projectForm, setProjectForm] = useState<IModelForm>({properties: []})

    const showModal = (project: string) => {
        setModalContent(project)
        setProjectForm({properties: ["User's email"]})
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: 'name'
        },
        {
            title: "Description",
            dataIndex: "description",
            key: 'description'
        },
        {
            title: "Owner",
            dataIndex: "owner",
            key: 'owner'
        },
        {
            title: "Add user",
            dataIndex: "name",
            key: 'name',
            render: (name: string) => {
              return <Button onClick={(_) => {showModal(name)}}>Add user</Button>
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            key: 'status',
            render: (status: string) => {
                let color = 'green';
                if (status === 'pending_approval') {
                    color = 'yellow';
                }
                // TODO Fix issue with duplicate key here for project.name
                return (
                    <Tag color={color} key={status}>
                    {status.toUpperCase()}
                    </Tag>
                );
            }
        }
    ]

    const fetchProjects = async () => {
      const projects = await ProjectService.apiGetProjects()
      setLoaded(true)
      setProjects(projects)
    }
    useEffect(() => {
      fetchProjects()
    }, [])
    return (
      <ProjectsContext.Provider value={{projects, fetchProjects}}>
        <Modal title={"Add user to project " + modalContent} open={isModalOpen} onOk={handleCreate} onCancel={handleCancel}>
            <Form form={form}
        name="user"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
    >
        <List className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={projectForm.properties}
            loading={!loaded}
            renderItem={(item: string) => (
        <Form.Item
        label={item}
        name={item}
        rules={[{ required: true, message: 'Please input this required field!' }]}
        >
        <Input />
        </Form.Item>
        )}/>
        </Form>
        </Modal>
        <Table dataSource={projects} columns={columns}/>
      </ProjectsContext.Provider>
    )
  }
