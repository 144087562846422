import React, {useEffect, useState} from 'react'
import * as antd from 'antd'
import { type FormProps } from 'antd'
import { useAuth } from "react-oidc-context";
import { Project, ProjectService } from '../services';
const { List, Skeleton, Button, Modal, Form, Input } = antd;

interface IModelForm {
    properties: any
}

type IProjectState = {
    projects: Project[],
    fetchProjects: () => void
  }

const ProjectsContext = React.createContext<IProjectState>({
    projects: [], fetchProjects: () => {}
  })

  export default function Projects() {
    const [form] = Form.useForm();

    const onFinish: FormProps["onFinish"] = (values) => {
        // const token = auth.user?.access_token;
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        //     body: JSON.stringify(values)
        // };
        // fetch('/api/create_project', requestOptions)
        //     .then(response => response.json())
        // console.log('Success:', values);

        const project = {
            name: values['name'],
            description: values['description'],
            owner: values['owner'],
        }

        ProjectService.apiPostProjectsName(project)

    };

    const onFinishFailed: FormProps["onFinishFailed"] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleCreate = () => {
    form
        .validateFields()
        .then((values) => {
        form.resetFields();
        onFinish(values);
        setIsModalOpen(false)

        })
        .catch((info) => {
        console.log("Validate Failed:", info);
        });
    };
    const auth = useAuth();
    const [projects, setProjects] = useState<Project[]>([])
    const [uris, setUris] = useState<Map<string, string>>(new Map())
    const [loaded, setLoaded] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [projectForm, setProjectForm] = useState<IModelForm>({properties: []})

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const fetchProjectForm = async () => {
        const p = {
            properties: [
                "name", "description", "owner"
            ]
        }
        setProjectForm(p)
    }
    const fetchUri = async (name: string) => {
        const uri = await ProjectService.apiGetProjectsSigninUri(name)
        setUris(uris.set(name, uri))
    }
    const fetchProjects = async () => {
      const projects = await ProjectService.apiGetProjectsForUser()

        projects.map(async (x) => {
            return await fetchUri(x.name)
        })

      //const uris = projects.map(async (x) => ({[x.name]: await ProjectService.apiGetProjectsSigninUri(x.name)}));
      setLoaded(true)
      //setUris(uris)
      setProjects(projects)
    }
    useEffect(() => {
      fetchProjects()
      fetchProjectForm()
    }, [])
    return (
      <ProjectsContext.Provider value={{projects, fetchProjects}}>
        <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={projects}
            loading={!loaded}
            renderItem={(item: ({name: string, description: string})) => (
            <List.Item>
                <Skeleton title={true} loading={!loaded} active>
                <List.Item.Meta
                    title={item.name}
                    description={item.description}
                />
                <div><Button onClick={() => window.open(uris.get(item.name), '_blank')}>To AWS Console</Button>
                </div>
                </Skeleton>
            </List.Item>
            )}
        />
        <Modal title="Create a new project" open={isModalOpen} onOk={handleCreate} onCancel={handleCancel}>
            <Form form={form}
        name="project"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
    >
        <List className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={projectForm.properties}
            loading={!loaded}
            renderItem={(item: string) => (
        <Form.Item
        label={item}
        name={item}
        rules={[{ required: true, message: 'Please input this required field!' }]}
        >
        <Input />
        </Form.Item>
        )}/>
        </Form>
        </Modal>
        <Button onClick={showModal}>Create new project</Button>
      </ProjectsContext.Provider>
    )
  }
