import React, {useEffect, useState} from 'react'
import * as antd from 'antd'
import { useAuth } from "react-oidc-context";
import { Project, ProjectService, User } from '../services';

const { Table, Tag } = antd;

type IUserState = {
  projects: Project[] | undefined;
  fetchUsers: ()=> void
}

const UsersContext = React.createContext<IUserState>({
    projects: undefined, fetchUsers: () => {}
  })

  export default function Users() {
    const auth = useAuth();
    const [projects, SetProjects] = useState<Project[]>([])
    //const [loaded, setLoaded] = useState(false)
    const columns = [
        {
            title: "Project",
            dataIndex: "name",
            key: 'name'
        },
        {
            title: "Users",
            dataIndex: "users",
            key: 'users',
            render: (projects: User[]) => (
                <>
                  {projects.map((project: {email: string}) => {
                    let color = 'green';
                    return (
                      <Tag color={color} key={ project.email}>
                        {project.email.toUpperCase()}
                      </Tag>
                    );
                  })}
                </>
              ),
        }
    ]
    const fetchUsers = async () => {
        const projects = await ProjectService.apiGetProjects()
      //setLoaded(true)
      SetProjects(projects)
    }
    useEffect(() => {
      fetchUsers()
    }, [])
    return (
      <UsersContext.Provider value={{projects, fetchUsers}}>
        <Table dataSource={projects} columns={columns}/>
      </UsersContext.Provider>
    )
  }
