import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AuthApp from './AuthApp';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './theme-provider';
import { AuthProvider } from "react-oidc-context";

declare global {
  const config: any;
}

const oidcConfig = {
  // authority: window._env_.OIDC_AUTHORITY,
  authority: config.REACT_APP_OIDC_AUTHORITY || process.env.REACT_APP_OIDC_AUTHORITY ,
  client_id: config.REACT_APP_OIDC_CLIENT_ID || process.env.REACT_APP_OIDC_CLIENT_ID,
  client_secret: config.REACT_APP_OIDC_CLIENT_SECRET || process.env.REACT_APP_OIDC_CLIENT_SECRET,
  redirect_uri: config.REACT_APP_OIDC_REDIRECT_URI || process.env.REACT_APP_OIDC_REDIRECT_URI,
  scope: config.REACT_APP_OIDC_SCOPE || process.env.REACT_APP_OIDC_SCOPE
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider>
    <AuthProvider {...oidcConfig}>
    <AuthApp />
    </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
