import {Component, ReactNode} from 'react';

import * as antd from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import Projects from './pages/Projects';
import Users from './pages/User';
import AdminUsers from './pages/AdminUsers';
import ProjectsDB from './pages/Projectsdb';
import Sidebar from './pages/Sidebar';

const { Layout, Typography } = antd;
const { Header, Content, Footer } = Layout;
const { Title } = Typography;

interface IProps {
}

interface IState {
  content: string | ReactNode;
}

class AppIndex extends Component<IProps, IState> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = { content: <Users/>};

    this.selectComponent = this.selectComponent.bind(this);
}

selectComponent = (event: MenuInfo) => {
  event.domEvent.preventDefault();

  switch(event.key)
  {
    case "User":
      this.setState({content: <Users/>})
      break

    case "Projects":
      this.setState({content: <Projects/>})
      break
    case "Users":
      this.setState({content: <AdminUsers/>})
      break
    case "DB_Projects":
      this.setState({ content: <ProjectsDB/>})
      break
  }
}

render () {
  return (
    <Layout style={{height:"100vh"}}>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div className="demo-logo" />
        <Title style={{color: '#e6ebe7'}}>Bifrost - Enterprise data portal</Title>
        {/* <Login/> */}
      </Header>
      <Content
        style={{
          padding: '0 48px',
        }}
      >
        <Layout
          style={{
            padding: '24px 0',
          }}
        >
          <Sidebar clickhandler={this.selectComponent}/>
          <Content
            style={{
              padding: '0 24px',
              minHeight: 280,
            }}
          >
            { this.state.content }
          </Content>
        </Layout>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        Bifrost ©{new Date().getFullYear()} Created by Data Minded
      </Footer>
    </Layout>
  );
};
}

export default AppIndex
