import React from "react";
import { useAuth, hasAuthParams } from "react-oidc-context";
import MainApp from './App'
import { OpenAPI } from "./services";

function App() {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = React.useState(false);

    // automatically sign-in
    React.useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
            //auth.signinSilent();
            auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    if (auth.isLoading) {
        // TODO Style this text a little bit
        return <div>Signing you in/out...</div>;
    }

    if (!auth.isAuthenticated) {
        return <div>Unable to log in</div>;
    }

    if (!auth.error && !auth.isLoading && auth.isAuthenticated) {
        const getToken = async () => {
        if (auth.user === undefined || auth.user === null) {
            // move to login page
            return 'no token'
        } else {
            return auth.user.access_token
        }
        }
        OpenAPI.TOKEN = getToken;
        console.log("called")
        //return <></>
        return <MainApp></MainApp>
    }

    return <button onClick={() => void auth.removeUser()}>Log out</button>;
}

export default App;
